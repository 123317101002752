<template>
  <div class="col-md-12 default">
    <zone-usage-code />
  </div>
</template>

<script>
import zoneUsageCode from "./ZoneUsageCode.vue";

export default {
  components: {
    zoneUsageCode
  },
  props: [],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
